<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="order-details-start  box-flex">
      <div class="review-container review-main text-capitalize position-relative  box-flex__wrapper">
        <div class="row">
          <div class="col-md-3" v-if="ordersDetail.length > 0">
            <img v-if="driverImg" v-lazy="driverImg" style="width: 50%">
          </div>
          <div class="col-md-6">
            <h4>{{ ordersTrackDetail.firstname }}</h4>
            <p>{{ $t('Order') }} #{{ orderId }}</p>
          </div>
          <div class="col-md-3" v-if="driverContactNo">
            <a :href="'tel:' + $auth.setting.country_code + '' + driverContactNo" class="drivr-call">{{ $t('Call')
            }}</a>
          </div>
          <div class="col-md-12 mt-2">
            <GmapMap v-if="isLoading && markers.length > 0" :center="center" :zoom="zoom"
              style="height: 600px !important">
              <GmapMarker :key="index" v-for="(m, index) in markers" :position="m" :clickable="true" :draggable="false"
                :icon="{ url: require('../assets/images/' + m.label) }" />
              <DirectionsRenderer travelMode="DRIVING" :origin=markers[0] :destination=markers[1] />
            </GmapMap>
          </div>
          <div class="order-offers" v-if="ordersTrackDetail.length == 0">
            <p class="text-center">{{ displayMsg }}</p>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Vue from 'vue'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapActions, mapMutations } from "vuex";
import VSocket from 'vue-socket.io';
import NavBar from "@/components/NavBar.vue";
import Footer from '../components/Footer.vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import config from '../config';
Vue.use(VueGoogleMaps);
import DirectionsRenderer from "../components/DirectionsRenderer.vue";

Vue.use(new VSocket({
  debug: true,
  connection: config.socket_url,
  vuex: {
    actionPrefix: "",
    mutationPrefix: ""
  }
}))
export default {
  name: "TrackOrder",
  components: { NavBar, Footer, VueGoogleAutocomplete, DirectionsRenderer },
  data() {
    return {
      zoom: 12,
      center: {
        lat: '',
        lng: '',
      },
      markers: [],
      mapOptions: { disableDefaultUI: true },
      isLoading: false,
      ordersTrackDetail: [],
      roomID: "",
      ordersDetail: [],
      driverImg: '',
      orderId: '',
      driverContactNo: '',
      imgError: false,
      displayMsg: ''
    };
  },

  sockets: {
    connect() {
      //  console.log("connect!!!");
    },
  },

  watch: {
    roomID: function () {
      // if(this.roomID !== '') {
      //     this.$socket.emit("room",this.roomID)
      //     this.sockets.subscribe('driver_location', (position) => {
      //       //this.center = position;
      //       this.center.lat = parseFloat(position.latitude);
      //       this.center.lng =  parseFloat(position.longitude);
      //       this.markers[2].lat = parseFloat(position.latitude);
      //       this.markers[2].lng = parseFloat(position.longitude);
      //       this.markers[2].label = 'Map_Pin.png';
      //     });        
      //    }      
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.$auth.setting && this.$auth.setting.is_enable_live_tracking_app == '0') {
        this.$router.go(-1);
      } else {
        this.orderTrackDetails();
        this.orderDetails();
      }
      // Vue.use(VueGoogleMaps, {
      //   load: {
      //     key: this.$auth.setting.google_map_key,
      //     libraries: 'places',
      //   }
      // });
      VueGoogleMaps.loadGmapApi({
        key: this.$auth.setting.google_map_key,
        libraries: 'places'
      })
    }, 1500)
  },

  methods: {
    ...mapActions("order", ["viewTrackOrderDetail", "viewOrderDetail"]),
    ...mapMutations("order", ["liveTrack"]),

    isBase64Convert(str) {
      if (str === '' || str.trim() === '') { return false; }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
    },

    orderTrackDetails() {
      if (this.isBase64Convert(this.$route.params.order_id)) {
        this.viewTrackOrderDetail({
          vendor_id: this.$auth.getVendorId(),
          order_id: this.base64_decode(this.$route.params.order_id),
          is_langauge: this.$store.state.lang.locale
        }).then(data => {
          if (data.code == 200) {
            this.ordersTrackDetail = data.Result;
            this.roomID = "R" + this.$auth.getVendorId() + "S" + this.ordersTrackDetail.driverid;
            this.roomID = this.roomID;
            this.center.lat = parseFloat(data.Result.driverlatitude);
            this.center.lng = parseFloat(data.Result.driverlongitude);
            this.markers = [{
              lat: parseFloat(data.Result.destination_latitude),
              lng: parseFloat(data.Result.destination_longitude),
              label: 'icon_delivery_marker.png'
            },
            {
              lat: parseFloat(data.Result.pickup_latitude),
              lng: parseFloat(data.Result.pickup_longitude),
              label: 'icon_store_marker.png'
            },
            {
              lat: parseFloat(data.Result.driverlatitude),
              lng: parseFloat(data.Result.driverlongitude),
              label: 'Map_Pin.png'
            }
            ];
            this.isLoading = true;
            this.$socket.emit("room", this.roomID)
            this.sockets.subscribe('driver_location', (position) => {
              if (position.driverid == this.ordersTrackDetail.driverid) {
                this.center.lat = parseFloat(position.latitude);
                this.center.lng = parseFloat(position.longitude);
                this.markers[2].lat = parseFloat(position.latitude);
                this.markers[2].lng = parseFloat(position.longitude);
                this.markers[2].label = 'Map_Pin.png';
              }
            });
          } else {
            this.displayMsg = data.msg;
            this.ordersTrackDetail = [];
          }
        })
      } else {
        this.isLoading = true;
        this.ordersTrackDetail = [];
      }
    },

    orderDetails() {
      if (this.isBase64Convert(this.$route.params.order_id)) {
        this.viewOrderDetail({
          vendor_id: this.$auth.getVendorId(),
          order_id: this.base64_decode(this.$route.params.order_id),
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.ordersDetail = data.Result;
            this.driverImg = this.ordersDetail[0].driver_image;
            this.orderId = this.ordersDetail[0].order_id;
            this.driverContactNo = this.ordersDetail[0].contactno;
          }
        });
      } else {
        this.ordersDetail = [];
        this.loading = false;
      }
    },

    imageLoadError(event) {
      this.imgError = true;
    }
  },
};
</script>
